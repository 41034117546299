import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import RefreshIcon from "@mui/icons-material/Refresh";
import CenterFocusIcon from "@mui/icons-material/CenterFocusStrong";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
import breakpoints from "assets/theme/base/breakpoints";
import Chart from "./chart";
import SoftButton from "components/SoftButton";
import { EVENT_NODE_ADD_REACTANGLE, EVENT_NODE_ADD_CIRCLE, CHART_EVENTS_CLICK } from "./constant";

//Slide panel and other forms
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Formik, Form } from "formik";

//Axios
import useAxios from "hooks/useAxios";
import axios from "apis/apiV1";

import "./chart.scss";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Process page components
import BasicInfo from "layouts/pages/product-entity/components/BasicInfo";
import Assets from "layouts/pages/product-entity/components/Assets";
import Facility from "layouts/pages/product-entity/components/Facility";
import Emission from "layouts/pages/product-entity/components/Emission";

//Resource component
import Resource from "layouts/resource/new-resource";

//import EmissionComponent from "layouts/pages/product-emission/";
import EmissionComponent from "layouts/emission/new-emission";

import validations from "layouts/pages/product-entity/schemas/validations";
import form from "layouts/pages/product-entity/schemas/form";
import initialValues from "layouts/pages/product-entity/schemas/initialValues";

//Spinner for API call
import LoaderSpinner from "components/LoaderSpinner";

//Form function
function getSteps() {
  return ["1. Basic", "2. Assets", "3. Facility", "4.Emission"];
}

function getStepContent(stepIndex, formData, handleChildData, newProfile) {
  switch (stepIndex) {
    case 0:
      return <BasicInfo formData={formData} />;
    case 1:
      return <Assets formData={formData} />;
    case 2:
      return <Facility formData={formData} />;
    case 3:
      return <Emission formData={formData}
        onChildData={handleChildData}
        newProfile={newProfile} />;
    default:
      return null;
  }
}
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function D3() {
  let query = useQuery();
  let productName = query.get("product_name");
  let isNewCanvas = query.get("new") ? (query.get("new") === "true" ? true : false) : false;
  const { values } = breakpoints;
  const { size } = typography;
  const [open, setOpen] = useState(false);

  const { id } = useParams();

  //Side panel state
  const [isPaneOpen, setIsPaneOpen] = useState(false);
  const [isResourcePaneOpen, setIsResourcePaneOpen] = useState(false);

  //Get node id
  const [nodeId, setNodeId] = useState(null);

  const [canvasData, setCanvasData] = useState(null);

  //Steps
  const [activeStep, setActiveStep] = useState(0);
  const [posts, error, loading, axiosFetch] = useAxios();
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  //show add forms
  const [showForm, setShowForm] = useState(false);

  //Show or hide process form
  const [showProcess, setShowProcess] = useState(true);
  const [formValue, setFormValue] = useState(initialValues);

  //Show or hide process form
  const [showEmissionProfile, setShowEmissionProfile] = useState(false);

  //Get new emission from child component and pass it to procees step
  const [newProfile, setNewProfile] = useState([]);

  //resource state maintain
  const [resourceState, setResourceState] = useState(false);
  const [resourceData, setResourceData] = useState()

  //Set node data
  const [nodeData, setNodeData] = useState(null);

  //ref the element
  const canvasRef = useRef(null);
  const chartRef = useRef(null);

  const canvasDataInitialRender = useRef(true);

  const navigate = useNavigate();

  const handleShowForm = () => {
    setShowForm(true);
  };

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    const handleResponseCallback = (response) => {
      if (response.status == "success") {
        setNodeData({ title: values.processName });
        toast.success("Process data has been saved successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsPaneOpen(false);
      }
    };
    axiosFetch(
      {
        method: "post",
        url: "process/add",
        requestConfig: {
          processName: values.processName,
          description: values.description,
          emissionProfiles: values.emissionProfiles,
          emissionProfilesFacility: values.emissionProfilesFacility,
          profiles: values.profiles,
          node_id: nodeId,
          product_id: id,

          output_resource: values.output_resource,

          coproduct_co2_percent: values.coproduct_co2_percent,
          coproduct_co2: values.coproduct_co2,
          waste_co2_percent: values.waste_co2_percent,
          waste_co2: values.waste_co2,
          decomposition_assumptions: values.decomposition_assumptions,
          quantification_assumptions: values.quantification_assumptions,
          verification_results: values.verification_results,
          activity_selection_reasoning: values.activity_selection_reasoning,
          notes: values.notes,
          geography:values.geography,



        },
      },
      handleResponseCallback
    );

    setIsPaneOpen(false);
    actions.setSubmitting(false);
    actions.resetForm();
    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const clickeventFn = (e) => {
    var elementType = e.detail.type != undefined ? "process" : "resource";
    setNodeId(e.detail.id);
    if (elementType == "process") {
      setIsPaneOpen(true);
    }
    if (elementType == "resource") {
      setResourceData(e.detail);
      setResourceState(false);
      setIsResourcePaneOpen(true);
    }
  };

  const addNodeFn = (event, data) => { };

  function saveChart(chartData) {
    const handleResponse = (response) => {
      if (response.status == "success") {
        toast.success("Canvas has been saved successfully!");
        // refresh the page
        //navigate("/products/canvas/" + id + "?new=false&product_name=" + productName);
        window.location.reload();
      }
    };
    axiosFetch(
      {
        method: "post",
        url: "savecanvas",
        requestConfig: {
          canvas_data: chartData,
          product_id: id,
        },
      },
      handleResponse
    );
  }

  const handleCanvasResponse = (response) => {
    if (response.data && response.data.length > 0) {
      if (response.data[0].canvas_data && response.data[0].canvas_data.nodes.length > 0) {
        response = appendNodeId(response);
      }
      setCanvasData(response.data);
    } else {
      setCanvasData(false);
    }
  };

  const appendNodeId = (response) => {
    // Initialize separate counters for resource and process nodes
    let resourceCounter = 1;
    let processCounter = 1;

    response.data[0].canvas_data.nodes.forEach(function (node) {
      if (node.type && node.type == "react") {
        node.title = "(P" + processCounter + ") " + node.title;
        processCounter++; // Increment the process counter
      } else {
        node.title = "(R" + resourceCounter + ") " + node.title;
        resourceCounter++; // Increment the resource counter
      }
    });
    return response;
  };

  const fetchcanvas = () => {
    axiosFetch(
      {
        method: "get",
        url: "listcanvas/" + id,
      },
      handleCanvasResponse
    );
  };
  useEffect(() => {
    fetchcanvas();
  }, []);

  useEffect(() => {
    isPaneOpen === true ? fetchProcess() : "";
    nodeId !== null ? chartRef.current.updateSingleNodeFromID(nodeId, nodeData) : "";
    if (isResourcePaneOpen) {
      setIsResourcePaneOpen(false);
    }
  }, [isPaneOpen, nodeData]);

  useEffect(() => {
    var aPaneOpen = false;
    if (isPaneOpen || isResourcePaneOpen) {
      aPaneOpen = true;
    }
    if (chartRef && chartRef.current) {
      chartRef.current.setPaneState(aPaneOpen);
    }
  }, [isPaneOpen, isResourcePaneOpen]);

  useEffect(() => {
    if (canvasDataInitialRender.current) {
      // Skip the effect on initial render
      canvasDataInitialRender.current = false;
    } else {
      //if (canvasData.length > 0) {
      init();
      // }
    }
  }, [canvasData]);

  const init = () => {
    const canvas = canvasRef.current;
    const chart = chartRef.current;

    if (!chart) {
      // create a new chart instance if none exists
      chartRef.current = new Chart(
        saveChart,
        window.Blob,
        clickeventFn,
        canvasData ? canvasData : []
      );
      const graphwidth = document.getElementById("graph");
      graphwidth.addEventListener(EVENT_NODE_ADD_REACTANGLE, addNodeFn);
      graphwidth.addEventListener(EVENT_NODE_ADD_CIRCLE, addNodeFn);
      graphwidth.addEventListener(CHART_EVENTS_CLICK, clickeventFn);
    }
    //if (chart && chart.unsubscribe) {
    if (chart && chart.unsubscribe) {
      return () => {
        const graphwidth = document.getElementById("graph");
        graphwidth.removeEventListener(EVENT_NODE_ADD_REACTANGLE, addNodeFn);
        graphwidth.removeEventListener(EVENT_NODE_ADD_CIRCLE, addNodeFn);
        graphwidth.removeEventListener(CHART_EVENTS_CLICK, clickeventFn);
        if (chart && chart.unsubscribe) {
          chart.unsubscribe();
        }
      };
    }
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChildData = (data) => {
    setShowProcess(!data);
    setShowEmissionProfile(data);
  };

  const hanldeFormData = (data) => {
    setNewProfile(data);
  };

  const fetchProcess = () => {
    setFormValue(initialValues);
    axiosFetch(
      {
        method: "get",
        url: "process/" + id + "/" + nodeId,
      },
      handleProcessResponse
    );
  };

  /*
const handleProcessResponse = (response) => {
  if (response.message != "Not Found.") {
    let responseData = response?.data;
    let formattedResponseData = {
      processName: responseData.processName,
      description: responseData.description,
      emissionProfiles: responseData.emissionProfiles,
      emissionProfilesFacility: responseData.emissionProfilesFacility,
      profiles: responseData.profiles,

      coproduct_co2_percent: responseData.coproduct_co2_percent
        ? responseData.coproduct_co2_percent
        : 0,
      coproduct_co2: responseData.coproduct_co2 ? responseData.coproduct_co2 : 0,
      waste_co2_percent: responseData.waste_co2_percent ? responseData.waste_co2_percent : 0,
      waste_co2: responseData.waste_co2 ? responseData.waste_co2 : 0,
    };

    setFormValue(formattedResponseData);
  }
};
  */
  const handleProcessResponse = (response) => {
    if (response.message != "Not Found.") {
      setFormValue(response.data);
    }
  };
  const handleResourceState = (newState) => {
    setResourceState(newState);
  };

  const handleNodeData = (data) => {
    setNodeData(data);
  };

  function getSourceNodeIdsFromTarget(chartRef, targetNodeId) {
    // Initialize an array to hold the IDs of source nodes
    let resourceNodeIds = [];
    let processNodeIds = [];

    // Check if chartRef and its edges property exist
    if (chartRef && chartRef.edges && Array.isArray(chartRef.edges)) {
      // Iterate over the edges array
      chartRef.edges.forEach(edge => {
        // Check if the current edge's target node ID matches the specified targetNodeId
        if (edge.target && edge.target.id === targetNodeId) {
          const sourceNode = chartRef.nodes.find(node => node.id === edge.source.id);
          if (sourceNode.type === "react") {
            processNodeIds.push(sourceNode.id);
          } else {
            resourceNodeIds.push(sourceNode.id);
          }
        }
      });
    }
    let nodeIds = { resourceNodeIds, processNodeIds };
    return nodeIds;
  }
  const fetchConnectedNodesInfo = async (nodeId) => {
    // get input resources/processes connected to this process
    const inputNodeIds = getSourceNodeIdsFromTarget(chartRef.current, nodeId);
    console.log(inputNodeIds);

    // fetch resources
    const fetchPromises = inputNodeIds.resourceNodeIds.map(nid => {
      return new Promise((resolve, reject) => {
        axiosFetch({
          method: "get",
          url: `/resource/${id}/${nid}`,
        }, (response, error) => {
          if (error) {
            reject(error);
          } else {
            resolve(response);
          }
        });
      });
    });

    // fetch processes
    fetchPromises.push(...inputNodeIds.processNodeIds.map(nid => {
      return new Promise((resolve, reject) => {
        axiosFetch({
          method: "get",
          url: `/process/${id}/${nid}`,
        }, (response, error) => {
          if (error) {
            reject(error);
          } else {
            resolve(response);
          }
        });
      });
    }));

    try {
      const responses = await Promise.all(fetchPromises);
      // All API calls have completed here
      // responses is an array of responses from all API calls
      let input_resources = [];

      responses.forEach(response => {
        if (response.data) {
          // check if data has resID
          if (response.data.material) {
            // this is a resource
            const inputResource = {
              name: response.data.name,
              description: response.data.description,
              unit_of_measurement: response.data.uom,
              units: response.data.units
            };
            input_resources.push(inputResource);
          } else {
            if (response.data.output_resource) {
              const outputResource = {
                name: response.data.output_resource.name,
                description: response.data.output_resource.description,
                unit_of_measurement: response.data.output_resource.unit_of_measurement,
                units: response.data.output_resource.units
              };
              input_resources.push(outputResource);
            }
          }
        }
      });
      console.log(input_resources);
      return input_resources;
    } catch (error) {
      // Handle error here
      console.error(error);
    }
  };

  const fetchEcoDecomposedMaterials = async (values) => {
    const name = values.processName;
    const description = values.description;
    const inputResources = await fetchConnectedNodesInfo(nodeId);
    const geography = values.geography || "Global";
    axiosFetch(
      {
        method: "post",
        url: "/ai/getdecomposedmaterials",
        requestConfig: {
          product_id: id,
          material_name: name,
          material_description: description,
          enable_decompose: true,
          input_resources: inputResources,
          node_type: "process",
          geography: geography,
        },
      },
      (response, error) => handleEcoDecompositionResponse(response, error, values)
    );
  };

  const handleEcoDecompositionResponse = async (response, error, values) => {
    console.log(response);
    if (response.data) {
      const decomposedMaterials = response.data.decomposed_materials;
      const output_resource = response.data.output_resource;
      const decomposition_assumptions = response.data.decomposition_assumptions;
      const quantification_assumptions = response.data.quantification_assumptions;
      const verification_results = response.data.verification_results;
      const activity_selection_reasoning = response.data.activity_selection_reasoning;
      const geography= response.data.geography;
      const notes = response.data.notes;
      if (decomposedMaterials && decomposedMaterials.length > 0) {
        // Update the form with the decomposed materials

        const decomposedMaterialsForm = decomposedMaterials.map((material, index) => {
          return {
            name: material.decomposed_material_activity_name,
            value: material.decomposed_material_activity_name,
            label: material.decomposed_material_activity_name,
            uom: material.decomposed_material_unit_of_measurement,
            method: "ecoinvent",
            units: material.decomposed_material_units,
            co2: material.co2,
            ghgfactor: material.co2_factor,
            ghg_factor: material.co2_factor,
            accuracy: material.accuracy,
            notes: material.notes,
            activityUUIDs: {
              datasource: material.datasource,
              activityName: material.decomposed_material_activity_name,
              activityUUID: material.activity_uuid,
              activityUUID_APOS: material.activityUUID_APOS,
              activityUUID_consequential: material.activityUUID_consequential,
              activityUUID_en15804: material.activityUUID_en15804,
              accuracy: material.accuracy,
              units: material.decomposed_material_units,
              unit_of_measurement: material.decomposed_material_unit_of_measurement,
              geography: material.geography,
            },
          };
        });

        // first call the API to create the new emission profiles if they don't exist and get their IDs
        const fetchPromises = decomposedMaterialsForm.map(material => {
          return new Promise((resolve, reject) => {
            axiosFetch({
              method: "post",
              url: "emissionprofileadd",
              requestConfig: {
                name: material.name,
                uom: material.uom,
                method: material.method,
                units: material.units,
                co2: material.co2,
                ghgfactor: material.ghgfactor,
                ghg_factor: material.ghg_factor,
                accuracy: material.accuracy,
                notes: material.notes,
                activityUUIDs: material.activityUUIDs,
                // security_isPublic: visibilityOptions.security_isPublic,
                // security_organisation_visibility: visibilityOptions.security_organisation_visibility,
              },
            }, (response, error) => {
              if (error) {
                reject(error);
              } else {
                resolve(response);
              }
            });
          });
        });
        try {
          const responses = await Promise.all(fetchPromises);
          // update decomposedMaterialsForm to include the new emission profile ID
          responses.forEach((response, index) => {
            if (response.data) {
              decomposedMaterialsForm[index].profile_id = response.data.profileID;
            }
          });
          // Update the state with the new emission profiles
          setFormValue(prevFormValue => ({
            ...values,
            output_resource,
            profiles: decomposedMaterialsForm,
            decomposition_assumptions,
            quantification_assumptions,
            verification_results,
            activity_selection_reasoning,
            notes,
            geography,
          }));
        }
        catch (error) {
          // Handle error here
          console.error(error);
        }
      }
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading && <LoaderSpinner />}
      <SoftBox py={2} style={{ border: "1px solid #e2e2e2" }}>
        <Grid container>
          <Grid xs={12} style={{ position: "relative" }}>
            <div id="graph" ref={canvasRef}></div>
            {/* <span id="product-name">{productName}</span> */}
            <SoftButton id="download-input" variant="gradient" color="success">
              Save `{productName}`
            </SoftButton>
          </Grid>
          <Grid xs={12} style={{ position: "absolute", left: 0, top: 0 }}>
            <div id="toolbox">
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <SoftBox>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                      style={{ height: "100vh" }}
                    >
                      <SoftButton id="circle" variant="text" color="primary">
                        Resource
                      </SoftButton>
                      <SoftButton id="reactangle" variant="text" color="primary">
                        Process
                      </SoftButton>
                    </Grid>
                  </SoftBox>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={3}>
            <SoftBox className="controls">
              <IconButton id="zoom-in" vairant="gradient" color="secondary" className="map-control">
                <AddIcon />
              </IconButton>
              <div className="divider"></div>
              <IconButton
                id="zoom-out"
                vairant="gradient"
                color="secondary"
                className="map-control"
              >
                <RemoveIcon />
              </IconButton>
              <div className="divider"></div>
              <IconButton
                id="reset-zoom"
                vairant="gradient"
                color="secondary"
                className="map-control"
              >
                <CenterFocusIcon />
              </IconButton>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>

      <SlidingPane
        overlayClassName="some-custom-overlay-class drawer-update-information"
        isOpen={isPaneOpen}
        title="Process"
        onRequestClose={() => {
          setIsPaneOpen(false);
        }}
        width="800px"
        style={{ zIndex: 1100 }}
      >
        <Grid style={{ display: showProcess ? "block" : "none", }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Formik
            initialValues={formValue || initialValues}
            validationSchema={currentValidation}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form id={formId} autoComplete="off">
                <Card sx={{ height: "100%" }}>
                  <SoftBox p={2}>
                    <SoftBox>
                      {activeStep === 0 && (
                        <SoftBox display="flex" justifyContent="flex-end">
                          <SoftButton
                            style={{ marginLeft: "10px", height: "50px" }}
                            disabled={!values.processName}
                            variant="gradient"
                            color="primary"
                            onClick={() => fetchEcoDecomposedMaterials(values)}
                          >
                            Copilot Compose
                          </SoftButton>
                        </SoftBox>
                      )}
                      {getStepContent(
                        activeStep,
                        {
                          values,
                          touched,
                          formField,
                          errors,
                          setFieldValue,
                        },
                        handleChildData,
                        newProfile
                      )}
                      <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                        {activeStep === 0 ? (
                          <SoftBox />
                        ) : (
                          <SoftButton variant="gradient" color="light" onClick={handleBack}>
                            back
                          </SoftButton>
                        )}
                        <SoftButton
                          disabled={isSubmitting}
                          type="submit"
                          variant="gradient"
                          color="dark"
                        >
                          {isLastStep ? "save" : "next"}
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid style={{ display: showEmissionProfile ? "block" : "none" }}>
          <EmissionComponent
            isCalled="true"
            onChildData={handleChildData}
            onChildFormData={hanldeFormData}
          />
        </Grid>
      </SlidingPane>
      <SlidingPane
        overlayClassName="drawer-update-information"
        isOpen={isResourcePaneOpen}
        title="Resource"
        onRequestClose={() => {
          setIsResourcePaneOpen(false);
        }}
        width="800px"
      >
        <Resource node={nodeId} resource={resourceData} onUpdate={handleResourceState} nodeData={handleNodeData} />
      </SlidingPane>
      <Footer />
    </DashboardLayout>
  );
}

export default D3;
