import React, { useRef, useState } from "react";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import FormField from "layouts/assets/new-asset/components/FormField";
import useAxios from "hooks/useAxios";
import Grid from "@mui/material/Grid";
import SoftTypography from "components/SoftTypography";
import { Formik, Form } from "formik";
import { saveAs } from 'file-saver';
import { toast } from "react-toastify";
import Papa from "papaparse";

import LoaderSpinner from "components/LoaderSpinner";

function CsvGenerator() {
    const [file, setFile] = useState(null);
    const [posts, error, loading, axiosFetch] = useAxios();
    const csvFileInputRef = useRef(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFile(file);
            toast.success("CSV file uploaded successfully!");
        }
    };

    const handleCsvButtonClick = () => {
        // Clear the current file input value
        if (csvFileInputRef.current) {
            csvFileInputRef.current.value = "";
        }
        setFile(null);
    };


    const handleProcessAndDownload = async (values) => {
        if (!file) return;

        const reader = new FileReader();
        reader.onload = async (e) => {
            // convert CSV to json
            const text = e.target.result;
            const jsonArray = await convertCsvToJson(text);


            axiosFetch(
                {
                    method: "post",
                    url: "/ai/generatecsvdata",
                    requestConfig: {
                        productName: values.productName,
                        productDescription: values.productDescription,
                        bom: jsonArray,
                        source: "playground",
                    },
                },
                (response) => {
                    const reorderedData = response.data.map(item => {
                        return {
                            name: item.name,
                            description: item.description,
                            unit_of_measurement: item.unit_of_measurement,
                            units: item.units,
                            node_type: item.node_type,
                            parent_node: item.parent_node,
                            geography: item.geography,
                            quality_index: item.quality_index,
                            quality_index_notes: item.quality_index_notes,
                            //linked_facility: item.linked_facility,
                            //linked_asset: item.linked_asset,
                            //waste_ratio: item.waste_ratio,
                            //notes: item.notes,
                            enable_decompose: item.enable_decompose
                        };
                    });
                    const csvContent = convertJsonToCsv(reorderedData);
                    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                    const currentDate = new Date();
                    const formattedDate = currentDate.toISOString().split('T')[0];
                    saveAs(blob, values.productName + "_" + formattedDate + "_Emvide.csv");
                }
            );



        };
        reader.readAsText(file);
    };


    const convertCsvToJson = (file) => {
        return new Promise((resolve, reject) => {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true, // Automatically skips empty lines in the input
                complete: (results) => {
                    // Filter out rows that are completely empty or have undefined values for every column
                    const filteredData = results.data.filter(row =>
                        Object.values(row).some(value => value !== undefined && value !== '')
                    );
                    resolve(filteredData);
                },
                error: (error) => {
                    reject(error);
                },
            });
        });
    };

    const convertJsonToCsv = (jsonData) => {
        return Papa.unparse(jsonData, {
            header: true,
        });
    };

    return (
        <SoftBox mt={0}>
            {loading && <LoaderSpinner />}
            <Formik
                initialValues={{ productName: "", productDescription: "" }}
                onSubmit={handleProcessAndDownload}
            >
                {({ values, handleChange, handleSubmit }) => (
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                        <SoftBox my={3}>
                            <Card>
                                <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={2}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <SoftTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                            >
                                                CSV Generator
                                            </SoftTypography>
                                            <SoftTypography fontSize="small">
                                                Turn any BOM CSV into Emvide CSV Template.
                                            </SoftTypography>
                                        </Grid>

                                        {/* Input Fields */}
                                        <Grid item xs={12}>
                                            <FormField label="Product Name" name="productName" value={values.productName} onChange={handleChange} />
                                            <FormField label="Product Description" name="productDescription" value={values.productDescription} onChange={handleChange} multiline rows={4} />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <input
                                                accept=".csv"
                                                id="contained-button-file"
                                                type="file"
                                                ref={csvFileInputRef}
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange}
                                            />
                                            <label htmlFor="contained-button-file">
                                                <SoftButton variant="contained" component="span" onClick={handleCsvButtonClick}>
                                                    Upload CSV
                                                </SoftButton>
                                            </label>
                                            <SoftButton
                                                variant="gradient"
                                                color="primary"
                                                type="submit"
                                                disabled={!values.productName || !file}
                                                style={{ marginLeft: '10px' }}

                                            >
                                                Generate CSV
                                            </SoftButton>
                                        </Grid>

                                    </Grid>
                                </SoftBox>
                            </Card>
                        </SoftBox>
                    </Form>
                )}
            </Formik>
        </SoftBox>
    );
}

export default CsvGenerator;
